<template>
    <div>
        <SelectModal
            v-if="selectModal.open"
            translation-base="ProductionProgress.SelectModal."
            :title="selectModal.title"
            :selected-id="selectModal.selectedId"
            :list="selectModal.data"
            :multiple="field.industrialStructParameterType === 'select_multiple'"
            :is-custom-fields="true"
            @select-item="(value) => handleSelectModalClick(value, field)"
            @close="handleCloseFieldModal()"
        />
        <InputModal
            v-if="inputModal"
            :data="selectedFieldInputModal"
            :is-custom-fields="true"
            @setQuantityValue="(context) => setValueInputModal(context.quantity, field)"
            @close="handleCloseFieldModal()"
        />
        <InputTextModal
            v-if="inputText"
            :modal-name="field.label"
            :input-value="fieldValue"
            :is-text-area="field.industrialStructParameterType === 'input_text_area' ? true : false"
            @setValue="(value) => setValueInputModal(value, field)"
            @close="handleCloseFieldModal()"
        />
        <InputPhotoModal
            v-if="photoModal"
            :modal-name="field.label"
            :input-value="fieldValue"
            :file-name="field.name"
            @removePhoto="
                field.value = null;
                photoModal = false;
            "
            @handleFileUpload="(event) => handleFileUpload(event, field)"
            @close="handleCloseFieldModal()"
        />
        <LocationModal
            v-if="locationModal"
            :value="fieldValue"
            :modal-name="field.label"
            @close="handleCloseFieldModal()"
            @setValue="(value) => setValueInputModal(value, field)"
        />
        <DateTimePicker
            v-if="
                field.industrialStructParameterType === 'datepicker' ||
                field.industrialStructParameterType === 'datetime' ||
                field.industrialStructParameterType === 'range_dates' ||
                field.industrialStructParameterType === 'input_hour'
            "
            ref="dateTimePickerRef"
            v-model="datepicker"
            :datepicker-id="`${field.id}`"
            position="bottom"
            :range="field.industrialStructParameterType === 'range_dates'"
            :type="field.industrialStructParameterType === 'input_hour' ? 'time' : 'date'"
            :week-days="setWeekDays()"
            :month-names="setMonths()"
            :block-dates="blockDates"
            :txt-cancel-button="$t('Datepicker.Cancel')"
            :txt-save-button="$t('Datepicker.Save')"
            :txt-time="$t('Datepicker.Time')"
            :txt-date="$t('Datepicker.Date')"
            :max-range-dates="maxRangeDates"
            class="h-100"
            @input="(value) => handleDatepickerChange(value, field)"
        >
            <template v-slot:input>
                <div
                    :id="`field-${field.id}`"
                    :class="[
                        'field-card-container',
                        { 'disabled-card': field.disabled },
                        { 'card-important': field.important }
                    ]"
                >
                    <div class="container-icon">
                        <div v-if="isIconString(field.icon)" class="icon" v-html="field.icon" />
                    </div>
                    <div :class="['container-content', field.important ? 'pr-0' : '']">
                        <span class="container-title">
                            {{ field.label }}
                        </span>
                        <div class="container-info">
                            <div class="container-value">
                                <span>{{ getPlaceholder(field) }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="field.important" class="important-container">
                        <ImportantIcon />
                    </div>
                    <Tooltip :target="`field-${field.id}`" placement="top">
                        <div class="text-left">
                            <div>
                                {{ $t("ProductionProgress.ImportantField") }}
                            </div>
                        </div>
                    </Tooltip>
                </div>
            </template>
        </DateTimePicker>
        <div
            v-else
            :id="`field-${field.id}`"
            :class="[
                'field-card-container',
                { 'disabled-card': field.disabled },
                { 'card-important': field.important }
            ]"
            @click="openModalField(field)"
        >
            <div v-if="loadingPhoto" class="container-icon">
                <LoadSpinner class="loading" />
            </div>
            <div v-else class="container-icon">
                <template v-if="field.industrialStructParameterType === 'checkbox'">
                    <b-form-checkbox
                        :id="field.name"
                        v-model="field.value"
                        name="resource-check-button"
                        :switch="false"
                        class="check-button p-0"
                    />
                </template>
                <template v-else>
                    <div v-if="isIconString(field.icon)" class="icon" v-html="field.icon" />
                </template>
            </div>
            <div :class="['container-content', field.important ? 'pr-0' : '']">
                <span
                    :class="[
                        'container-title',
                        field.industrialStructParameterType === 'checkbox' ? 'p-0' : ''
                    ]"
                >
                    {{ field.label }}
                </span>
                <div class="container-info">
                    <div class="container-value">
                        <span>{{ getPlaceholder(field) }}</span>
                    </div>
                </div>
            </div>
            <div v-if="field.important" class="important-container">
                <ImportantIcon />
            </div>
            <Tooltip :target="`field-${field.id}`" placement="top">
                <div class="text-left">
                    <div>{{ $t("ProductionProgress.ImportantField") }}</div>
                </div>
            </Tooltip>
        </div>
    </div>
</template>

<script>
    import DateTimePicker from "components-code/date-time-picker/DatePickerFull.vue";
    import LoadSpinner from "@/assets/images/pages/load-icon-fields.svg";
    import ImportantIcon from "@core/assets/icons/important-icon.svg";
    import Tooltip from "@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue";
    import { mapMutations } from "vuex";
    import moment from "moment";
    import SelectModal from "../SelectModal/SelectModal.vue";
    import InputModal from "../InputModal/InputModal.vue";
    import InputTextModal from "../InputTextModal/InputTextModal.vue";
    import InputPhotoModal from "../InputPhotoModal/InputPhotoModal.vue";
    import LocationModal from "../LocationModal/index.vue";
    import { BFormCheckbox } from "bootstrap-vue";

    export default {
        name: "CustomFields",
        components: {
            DateTimePicker,
            LoadSpinner,
            Tooltip,
            ImportantIcon,
            SelectModal,
            InputModal,
            InputTextModal,
            InputPhotoModal,
            LocationModal,
            BFormCheckbox
        },
        props: {
            field: {
                type: Object,
                default: {}
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectModal: {
                    selectedId: null,
                    open: false,
                    title: "",
                    data: []
                },
                inputText: false,
                inputModal: false,
                selectedFieldInputModal: {
                    title: "",
                    quantityProp: 0
                },
                photoModal: false,
                loadingPhoto: false,
                locationModal: false,
                datepicker: []
            };
        },
        computed: {
            maxRangeDates() {
                return 7;
            },
            defaultDate() {
                return [
                    new Date(new Date().setDate(new Date().getDate() - (this.maxRangeDates - 1))),
                    new Date()
                ];
            }
        },
        watch: {
            loadingPhoto(v) {
                this.$emit("loadingPhoto", v);
            }
        },
        created() {
            if (!this.field.value) {
                this.datepicker =
                    this.field.industrialStructParameterType === "range_dates"
                        ? [...this.defaultDate]
                        : new Date();
            } else {
                this.datepicker = new Date(this.field.value);
                this.inputDatepicker = moment(this.field.value).format("DD/MM/YYYY HH:mm");
            }
        },
        mounted() {
            this.clearFields();
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            openModalField(field) {
                switch (field.industrialStructParameterType) {
                    case "input_numeric":
                        this.inputModal = true;
                        this.selectedFieldInputModal = {
                            title: field.label,
                            quantityProp: field.value
                        };
                        break;

                    case "select_unique":
                    case "select_multiple":
                        this.selectModal = {
                            selectedId: field.value,
                            open: true,
                            title: field.label,
                            data: field.industrialStructParameterOption
                        };
                        break;

                    case "input_text":
                    case "input_text_area":
                        this.inputText = true;
                        this.fieldValue = field.value;
                        break;

                    case "photo":
                        this.photoModal = true;
                        this.fieldValue = field.value;
                        break;

                    case "location":
                        this.locationModal = true;
                        this.fieldValue = field.value;
                        break;

                    default:
                        break;
                }
            },
            clearFields() {
                this.selectModal = {
                    selectedId: null,
                    open: false,
                    title: "",
                    data: []
                };
                this.inputText = false;
                this.inputModal = false;
                this.loadingPhoto = false;
                this.photoModal = false;
                this.locationModal = false;
                this.selectedFieldInputModal = {
                    title: "",
                    quantityProp: 0
                };
            },
            handleFileUpload(event, field) {
                this.photoModal = false;
                this.loadingPhoto = true;
                const file = event.target.files[0];
                if (file && file.type.includes("image/")) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        field.value = reader.result;
                    };
                    field.name = file.name;
                    this.loadingPhoto = false;
                }
            },
            getPlaceholder(field) {
                switch (field.industrialStructParameterType) {
                    case "photo":
                        return !field.value ? this.$t("AddPhoto") : field.name;

                    case "select_unique":
                        return !field.value ? this.$t("Operator.Select") : field.value;
                    case "select_multiple":
                        return !field.value || !field.value.length
                            ? this.$t("Operator.Select")
                            : field.value.join(", ");

                    case "input_numeric":
                        return !field.value ? "0" : field.value;

                    case "input_text_area":
                    case "input_text":
                        return !field.value ? this.$t("TypeHere") : field.value;

                    case "datepicker":
                        return !field.value ? "00-00-0000" : field.value;
                    case "input_hour":
                        return !field.value ? "00:00" : field.value;
                    case "range_dates":
                        return !field.value
                            ? `00-00-0000 ${this.$t("Operator.Until")} 00-00-0000`
                            : field.value;
                    case "location":
                        return !field.value ? this.$t("InsertLocation") : field.value.address_name;

                    default:
                        return "";
                }
            },
            setValueInputModal(quantity, field) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                field.value = quantity;
            },
            handleDatepickerChange(value, field) {
                this.datepicker = value;
                switch (field.industrialStructParameterType) {
                    case "datepicker":
                        field.value = moment(value).format("DD/MM/YYYY");
                        break;
                    case "input_hour":
                        field.value = moment(value).format("HH:mm");
                        break;
                    case "range_dates":
                        field.value = `${moment(value[0]).format("DD/MM/YYYY")} ${this.$t(
                            "Operator.Until"
                        )} ${moment(value[1]).format("DD/MM/YYYY")}`;
                        break;

                    default:
                        break;
                }
            },
            setWeekDays() {
                const weekdays = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday"
                ];

                return weekdays.map((day) => ({
                    name: this.$t(`Datepicker.Weekdays.Full.${day}`),
                    short: this.$t(`Datepicker.Weekdays.Short.${day}`)
                }));
            },
            setMonths() {
                const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ];

                return months.map((month) => ({
                    name: this.$t(`Datepicker.Months.Full.${month}`),
                    short: this.$t(`Datepicker.Months.Short.${month}`)
                }));
            },
            blockDates(date) {
                const currentDate = moment(moment().format("YYYY-MM-DD"));
                const inspected = moment(moment(new Date(date)).format("YYYY-MM-DD"));
                return inspected > currentDate;
            },
            handleSelectModalClick(value, field) {
                const isMultiple = field.industrialStructParameterType === "select_multiple";
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                field.value = isMultiple ? value.items : value.item.id;
            },
            handleCloseFieldModal() {
                this.clearFields();
                this.selectModal.open = false;
                this.UPDATE_FLAG_SHOW_OVERLAY(false);
            },
            isIconString(icon) {
                return typeof icon === "string" && icon.startsWith("<svg");
            }
        }
    };
</script>
<style lang="scss">
    .field-card-container {
        width: 100%;
        height: 100%;

        border: 1px solid #cfc4be;
        border-radius: 16px;
        background: #fafafa;

        display: flex;
        user-select: none;
        cursor: pointer;

        &:hover {
            background: #ffede2;
            border-color: #974900;

            .container-title,
            .container-value span {
                color: #974900 !important;
            }

            .container-icon {
                border-color: #974900;

                .icon svg {
                    fill: #974900;
                }

                .custom-checkbox .custom-control-label::before {
                    border-color: #974900;
                }
            }
        }

        &:active {
            background: #ffdbc4;
        }

        &.disabled-card {
            background: #e0e0e0 !important;
            border-color: #7e7570 !important;
            cursor: inherit !important;

            .container-title {
                color: #7e7570 !important;
            }
            svg {
                fill: #998f8a !important;
            }

            .container-icon {
                border-color: #7e7570 !important;

                .icon svg {
                    fill: #7e7570 !important;
                }
            }

            .container-value span {
                color: #7e7570 !important;
            }

            .important-container {
                display: flex;
                align-items: center;
                padding-right: 24px;
                span {
                    color: #4c4541 !important;
                }
            }
        }

        &.card-alert {
            border-color: #ffa726;

            &:hover {
                border-color: #974900;
                .container-icon {
                    border-color: #974900;
                }

                .important-container {
                    span {
                        color: #974900;
                    }

                    svg {
                        fill: #974900;
                    }
                }
            }

            .container-icon {
                border-color: #ffa726;
            }
        }

        .container-icon {
            max-width: 68px;
            min-width: 68px;

            border-right: 1px solid #cfc4be;

            display: flex;
            align-items: center;
            justify-content: center;

            .custom-checkbox,
            .custom-control-input,
            .custom-control-label:after,
            .custom-control-label::before {
                cursor: pointer;
                z-index: 0;
                width: 24px;
                height: 24px;
            }

            .custom-control-label:after {
                top: 0 !important;
            }

            .custom-control-label {
                margin: 0;
                line-height: 24px;
            }

            .custom-control-input:checked ~ .custom-control-label::before {
                background-color: #974900;
                box-shadow: none !important;
                border-color: #cfc4be !important;
            }

            .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
                background-color: #e2e2e2;
                border-color: #e2e2e2;
            }

            .custom-control-input:focus ~ .custom-control-label::before {
                box-shadow: none !important;
                border-color: #e2e2e2 !important;
            }

            .loading {
                animation-name: loadingInfinity;
                animation-duration: 2s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
            }

            .icon {
                width: 24px;
                height: 24px;
                svg {
                    fill: #998f8a;
                }
            }
        }

        .container-content {
            width: 100%;
            padding: 24px 24px 24px 16px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            .container-title {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #998f8a;
                padding-bottom: 4px;
            }
            .container-info {
                span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;

                    word-break: break-all;
                    white-space: pre-wrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .container-value {
                    span {
                        color: #4c4541;
                    }
                }
            }
        }
        .important-container {
            display: flex;
            align-items: center;
            padding-right: 24px;

            span {
                color: #ffa726;
            }

            svg {
                fill: #ffa726;
            }
        }
    }

    @media (max-width: 768px) and (min-width: 425px) {
        .field-card-container {
            .container-icon {
                max-width: 72px !important;
                min-width: 72px !important;
            }

            .container-content {
                .container-title {
                    font-size: 18px !important;
                    line-height: 26px !important;
                }
                .container-info {
                    span {
                        font-size: 22px !important;
                        line-height: 30px !important;
                    }
                }
            }
        }
    }

    @media (max-width: 425px) {
        .field-card-container {
            .container-icon {
                min-width: 48px !important;
                max-width: 48px !important;

                .icon {
                    width: 16px;
                    height: 16px;
                }

                .custom-checkbox,
                .custom-control-input,
                .custom-control-label:after,
                .custom-control-label::before {
                    width: 16px;
                    height: 16px;
                }
            }

            .container-content {
                width: 100%;
                padding: 16px 24px 16px 16px;

                display: flex;
                flex-direction: column;
                justify-content: center;

                .container-title {
                    font-size: 12px !important;
                    line-height: 16px !important;
                }

                .container-info {
                    span {
                        font-size: 14px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }
    }
    @keyframes loadingInfinity {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
