<template>
    <div class="input-modal-text-container animation-open-modal-text">
        <ModalHeader :title="modalName" :close="closeModal" />

        <div class="input-person-container">
            <div class="input-person-wrapper">
                <div class="center-container">
                    <div class="wrapper-container">
                        <div class="text-input">
                            <div class="text-content">
                                <textarea
                                    v-if="isTextArea"
                                    ref="textAreaField"
                                    class="borderless"
                                    :value="inputValue"
                                    :placeholder="$t('TypeHere')"
                                    rows="2"
                                    @blur="setTextAreaFocus"
                                    @input="
                                        (v) => {
                                            currentInputValue = v.target.value;
                                        }
                                    "
                                />
                                <input
                                    v-else
                                    ref="inputTextField"
                                    class="borderless"
                                    inputmode="text"
                                    :value="inputValue"
                                    :placeholder="$t('TypeHere')"
                                    @blur="setInputFocus"
                                    @input="
                                        (v) => {
                                            currentInputValue = v.target.value;
                                        }
                                    "
                                />

                                <div class="gray-row" />
                            </div>
                        </div>
                    </div>
                    <Button button-type="save" @click="save">
                        {{ $t("IntegrationPage.Save") }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/pages/operator/components/InputTextModal/components/Button/Button.vue";
import { BFormTextarea } from "bootstrap-vue";
import ModalHeader from "@/views/pages/operator/components/InputTextModal/components/ModalHeader/ModalHeader.vue";
import { mapMutations } from "vuex";

export default {
    components: {
        Button,
        ModalHeader,
        BFormTextarea,
    },
    props: {
        inputValue: {
            type: String,
            default: "",
        },
        modalName: {
            type: String,
            default: "",
        },
        isTextArea: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            closeKeyboard: false,
            currentInputValue: this.inputValue,
        };
    },
    computed: {},
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
        this.addOpenAnimation();

        setTimeout(
            () =>
                this.isTextArea
                    ? this.setTextAreaFocus()
                    : this.setInputFocus(),
            500
        );
    },
    methods: {
        ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
        setInputFocus() {
            !this.closeKeyboard && this.$refs.inputTextField?.focus();
        },
        setInputBlur() {
            this.$refs.inputTextField?.blur();
        },
        setTextAreaFocus() {
            !this.closeKeyboard && this.$refs.textAreaField?.focus();
        },
        setTextAreaBlur() {
            this.$refs.textAreaField?.blur();
        },
        addOpenAnimation() {
            const modal = document.querySelector(".input-modal-text-container");
            modal.classList.add("animation-open-modal-text");

            setTimeout(
                () =>
                    document
                        .querySelector(".input-modal-text-container")
                        .classList.remove("animation-open-modal-text"),
                1000
            );
        },
        closeModal() {
            const modal = document.querySelector(".input-modal-text-container");
            modal.classList.add("animation-close-modal");
            modal.classList.add("closed-modal");
            this.closeKeyboard = true;

            setTimeout(() => this.$emit("close", this.inputValue), 1000);
        },
        save() {
            this.$emit("setValue", this.currentInputValue);
            this.closeModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.input-modal-text-container {
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    &.closed-modal {
        top: 100vh;
    }

    .input-person-container {
        height: calc(100vh - 62px);
        display: flex;
        align-items: center;
        padding: 40px 45px;
        justify-content: center;

        .input-person-wrapper {
            display: flex;
            user-select: none;
            width: 100%;
            max-width: 818px;

            .center-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .wrapper-container {
                    padding: 0px 16px 16px;
                    display: flex;
                    flex-direction: column;

                    .text-input {
                        display: flex;
                        align-items: center;

                        .text-content {
                            width: 100%;
                            padding: 0 16px;

                            .borderless {
                                width: 100%;
                                resize: none;
                                border: none;
                                outline: none;
                                box-shadow: none;
                                color: #4c4541;
                                text-align: center;
                                font-size: 36px;
                                font-weight: 600;
                                line-height: 50px;

                                &::placeholder {
                                    color: #cfc4be;
                                }

                                &::-webkit-scrollbar {
                                    width: 7px;
                                }

                                &::-webkit-scrollbar-track {
                                    background: #fff;
                                    border-radius: 0 5px 5px 0;
                                }

                                &::-webkit-scrollbar-thumb {
                                    background-color: #cfc4be;
                                    border-radius: 9px;
                                }
                            }

                            .gray-row {
                                height: 2px;
                                background: #cfc4be;
                                display: block;
                                margin-top: 8px;
                            }
                        }
                        .side-button-container {
                            display: none;
                        }
                    }

                    .buttons-row-container {
                        margin-top: 24px;
                        gap: 0 16px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &.animation-open-modal-text {
        animation: openModal 0.5s normal;
        @keyframes openModal {
            0% {
                top: 100vh;
            }
            100% {
                top: 0;
            }
        }
    }

    &.animation-close-modal {
        animation: closeModal 0.5s normal;
        @keyframes closeModal {
            0% {
                top: 0;
            }
            100% {
                top: 100vh;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 593px) {
    .input-modal-text-container {
        .input-person-container {
            height: calc(100vh - 82px);
            align-items: flex-start;

            .side-button-container-extern {
                display: none !important;
            }

            .text-input {
                .side-button-container {
                    display: flex !important;
                }
            }
        }
    }
}

@media (max-width: 593px) {
    .input-modal-text-container {
        .input-person-container {
            height: calc(100vh - 58px);
            align-items: flex-start;
            padding: 24px 16px;

            .input-person-wrapper {
                .center-container {
                    .wrapper-container {
                        padding: 0 !important;

                        .text-input {
                            .text-content {
                                .borderless {
                                    font-size: 26px;
                                    line-height: 36px;
                                }
                                .gray-row {
                                    margin-top: 8px !important;
                                }
                            }

                            .side-button-container {
                                display: flex !important;
                            }
                        }
                    }
                    .buttons-row-container {
                        margin-top: 16px !important;
                    }
                }
            }

            .side-button-container-extern {
                display: none !important;
            }
        }
    }
}
</style>
