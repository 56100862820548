<template>
    <div class="map-container">
        <div
            ref="googleMap"
            class="map"
            @click="
                () => {
                    $emit('click');
                }
            "
        />
    </div>
</template>
<script>
import { useGoogleMapsLoader } from "@/libs/loader.js";

export default {
    props: {
        currentCoordinates: {
            type: Object,
            default: () => ({
                lat: 0,
                lng: 0,
            }),
        },
    },
    data() {
        return {
            myCoordinates: localStorage.getItem("coordinates"),
            map: null,
            marker: null,
            circle: null,
        };
    },
    computed: {
        mapCoordinates() {
            if (!this.map) {
                return {
                    lat: 0,
                    lng: 0,
                };
            }

            return {
                lat: Number(this.map.getCenter().lat().toFixed(4)),
                lng: Number(this.map.getCenter().lng().toFixed(4)),
            };
        },
    },
    watch: {
        currentCoordinates(newCoordinates) {
            if (newCoordinates) {
                this.myCoordinates = newCoordinates;
                this.updateMapCenter(newCoordinates);
            }
        },
    },
    async mounted() {
        const { loadGoogleMaps } = useGoogleMapsLoader();
        await loadGoogleMaps();

        let coordinatesParsed = this.myCoordinates;
        if (typeof this.myCoordinates === "string") {
            coordinatesParsed = JSON.parse(this.myCoordinates);
        }

        this.map = new google.maps.Map(this.$refs.googleMap, {
            center: coordinatesParsed,
            zoom: 15,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true,
        });

        this.circle = new google.maps.Circle({
            strokeColor: "#974900",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#97490029",
            fillOpacity: 0.35,
            map: this.map,
            center: coordinatesParsed,
            radius: 500,
        });

        this.marker = new google.maps.Marker({
            position: coordinatesParsed,
            map: this.map,
            icon: {
                url: "/assets/icons/location-marker.svg",
            },
        });

        this.map.addListener("center_changed", this.handleMapCenterChanged);
        this.map.addListener("dragend", this.handleMapDragEnd);
    },
    methods: {
        handleMapCenterChanged() {
            const newCenter = this.map.getCenter();
            const lat = newCenter.lat();
            const lng = newCenter.lng();

            this.marker.setPosition({ lat, lng });
            this.circle.setCenter({ lat, lng });
            this.myCoordinates = { lat, lng };
        },
        handleMapDragEnd() {
            this.$emit("updateLocation", this.myCoordinates);
        },
        updateMapCenter(coordinates) {
            if (this.map) {
                this.map.setCenter(coordinates);
                this.marker.setPosition(coordinates);
                this.circle.setCenter(coordinates);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.map-container {
    .map {
        width: 100%;
        height: 100%;
    }
}
</style>
