<template>
    <button :disabled="disabled" @click="$emit('click')">
        <span>
            <slot />
        </span>
    </button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    background: #974900;
    padding: 20px 35px;
    border-radius: 5px;
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #9f5714;
    }

    &:active {
        background: #a45f1f;
    }

    span {
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #ffffff;
    }

    &:disabled {
        border-radius: 5px;
        background: #ece0db;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
            0px 3px 3px 0px rgba(0, 0, 0, 0.12),
            0px 1px 8px 0px rgba(0, 0, 0, 0.2);

        span {
            color: #7e7570;
        }
    }
}

@media (max-width: 425px) {
    button {
        padding: 8px 21px !important;
        span {
            font-size: 14px !important;
            line-height: 20px !important;
        }
    }
}
</style>
