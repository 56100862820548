<template>
    <header class="header-container">
        <h1>
            {{
                isCustomFields
                    ? title
                    : $t(`ProductionProgress.InputModal.${title}`)
            }}
        </h1>
        <button @click="close">
            <CloseIcon v-if="windowWidth > 593 && windowWidth < 768" />
            <CloseIconSmall v-else />
        </button>
    </header>
</template>

<script>
import CloseIcon from "@/assets/images/icons/close-icon-1.svg";
import CloseIconSmall from "@/assets/images/icons/close-icon.svg";

export default {
    components: {
        CloseIcon,
        CloseIconSmall,
    },
    props: {
        title: {
            type: "Status" | "Resource" | "Reason",
            default: "Status",
        },
        isCustomFields: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Function,
            default: () => {},
        },
    },
    data: () => ({
        windowWidth: window.innerWidth,
    }),
};
</script>

<style lang="scss" scoped>
.header-container {
    display: flex;
    align-items: center;
    padding: 15.5px 29px;
    border-bottom: 1px solid #cfc4be;
    user-select: none;

    h1 {
        flex-grow: 1;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #4c4541;
        margin: 0;
    }

    button {
        padding: 0;
        border: none;
        background: none;
    }
}

@media (max-width: 768px) and (min-width: 593px) {
    .header-container {
        h1 {
            font-size: 36px !important;
            line-height: 50px !important;
        }
    }
}

@media (max-width: 593px) {
    .header-container {
        padding: 15.5px !important;

        h1 {
            font-size: 18px !important;
            line-height: 26px !important;
        }
    }
}
</style>
