import { Loader } from "@googlemaps/js-api-loader";

export function useGoogleMapsLoader() {
    const loadGoogleMaps = async () => {
        const loader = new Loader({
            apiKey: process.env.VUE_APP_GOGGLE_MAPS_API,
            libraries: ["places", "maps"],
        });
        await loader.load();
    };

    return { loadGoogleMaps };
}
