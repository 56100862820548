<template>
    <div :class="['sys-dropdown', `slip-${scheme}`]">
        <sys-button
            :full="true"
            :variant="variant"
            :scheme="'square'"
            :size="size"
            :noBorderRight="true"
            noShadow
            @click="$emit('click')"
        >
            <slot />
        </sys-button>

        <div :class="['sys-dropdown__divider', variant]" />

        <b-dropdown no-caret right>
            <template #button-content>
                <sys-button
                    class="sys-dropdown__drop-button"
                    :variant="variant"
                    :scheme="'square'"
                    :size="size"
                    noBorderLeft
                    noShadow
                >
                    <ArrowDown />
                </sys-button>
            </template>

            <slot name="droplist" />
        </b-dropdown>
    </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";

import SysButton from "@/@core/components/sys-button/index.vue";
import ArrowDown from "@core/assets/icons/arrow-down.svg";

export default {
    components: {
        SysButton,
        BDropdown,
        BDropdownItem,
        ArrowDown,
    },
    props: {
        full: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        buttonType: {
            type: String,
            default: "button",
        },
        variant: {
            type: String,
            default: "primary",
        },
        scheme: {
            type: String,
            default: "light",
        },
        size: {
            type: String,
            default: "md",
        },
        noBorderLeft: {
            type: Boolean,
            default: false,
        },
        noBorderRight: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.sys-dropdown {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    .dropdown {
        height: 100%;

        .dropdown-item {
            padding: 12px 21px 12px 21px !important;
            display: flex;
            align-items: center;
            gap: 9px;
            color: #998f8a;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            background-color: #fff;

            svg {
                height: 16px;
                width: 16px;

                path {
                    fill: #998f8a;
                }
            }

            &:hover {
                background-color: #fbeee8;
                color: #974900;

                svg path {
                    fill: #974900 !important;
                }
                span {
                    color: #974900 !important;
                }
            }

            &:active {
                background-color: #ffdbc4;
                color: #974900;

                svg path {
                    fill: #974900 !important;
                }
                span {
                    color: #974900 !important;
                }
            }

            &.disabled {
                svg {
                    path {
                        fill: #cfc4be !important;
                    }
                }
                .text-action-pipeline {
                    color: #cfc4be !important;
                }
            }
            span {
                margin-left: 9px;
            }
        }
    }

    .btn.dropdown-toggle {
        padding: 0;
        border: 0;
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }

    .sys-dropdown__divider {
        width: 1px;
        height: 100%;
    }

    .sys-dropdown__divider {
        &.outline {
            background: #974900;
        }

        &.solid {
            background: #ffffff;
        }
    }

    .sys-dropdown__drop-button {
        padding: 7px 12px !important;
        height: 100%;
    }
}
</style>
