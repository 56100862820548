<template>
    <button
        @click="$emit('click')"
        :disabled="disabled"
        :class="[
            'sys-button',
            !!scheme && scheme,
            !!scheme && !!status && `${scheme}-${status}`,
            !!scheme && !!icon && `${scheme}-icon`,
            !!scheme && !icon && `${scheme}-text-icon`,
            !!scheme && !!variant && `${scheme}-${variant}`,
            !!scheme && !!variant && !!size && `${scheme}-${variant}-${size}`,
            {
                full,
                noBorderLeft,
                noBorderRight,
                noShadow,
            },
        ]"
    >
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        full: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String,
            default: "default",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        buttonType: {
            type: String,
            default: "button",
        },
        variant: {
            type: String,
            default: "primary",
        },
        icon: {
            type: Boolean,
            default: false,
        },
        scheme: {
            type: String,
            default: "square",
        },
        size: {
            type: String,
            default: "md",
        },
        noBorderLeft: {
            type: Boolean,
            default: false,
        },
        noBorderRight: {
            type: Boolean,
            default: false,
        },
        noShadow: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.sys-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: fit-content;
    outline: none;
    user-select: none;

    &:focus {
        outline: none;
    }

    &.full {
        width: 100% !important;
    }

    &.noBorderLeft {
        border-left: none !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    &.noBorderRight {
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &.noShadow {
        box-shadow: none !important;
    }

    &.square {
        border-radius: 5px;

        &.square-default {
            &.square-outline {
                border: 1px solid #974900;
                color: #974900;
                background: transparent;

                svg {
                    fill: #974900;
                }

                &:hover {
                    background: #ffede2;
                }

                &:active {
                    background: #ffdbc4;
                }
            }

            &.square-contained {
                border: none;
                background: #974900;
                color: #fff;

                svg {
                    fill: #fff;
                }

                &:hover {
                    background: #9f5714;
                }

                &:active {
                    background: #a45f1f;
                }
            }
        }

        &.square-danger {
            &.square-outline {
                border: 1px solid #d32f2f;
                color: #d32f2f;
                background: transparent;

                svg {
                    fill: #d32f2f;
                }

                &:hover {
                    background: #ffcdd2;
                }

                &:active {
                    background: #ef9a9a;
                }
            }

            &.square-contained {
                border: none;
                background: #d32f2f;
                color: #fff;

                svg {
                    fill: #fff;
                }

                &:hover {
                    background: #e53935;
                }

                &:active {
                    background: #f44336;
                }
            }
        }

        &.square-text-icon {
            &.square-outline-xs {
                padding: 3px 13px;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.15px;
                gap: 6px;

                svg {
                    min-width: 12px;
                    max-width: 12px;
                    min-height: 12px;
                    max-height: 12px;
                }
            }

            &.square-outline-md {
                padding: 8px 20px;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.175px;
                gap: 8px;

                svg {
                    min-width: 14px;
                    max-width: 14px;
                    min-height: 14px;
                    max-height: 14px;
                }
            }

            &.square-outline-xl {
                padding: 19px 34px;
                font-size: 20px;
                font-weight: 600;
                line-height: 23px;
                letter-spacing: 0.25px;
                gap: 12px;

                svg {
                    min-width: 22px;
                    max-width: 22px;
                    min-height: 22px;
                    max-height: 22px;
                }
            }

            &.square-contained-md {
                padding: 9px 21px;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.175px;
                gap: 8px;

                svg {
                    min-width: 14px;
                    max-width: 14px;
                    min-height: 14px;
                    max-height: 14px;
                }
            }

            &.square-contained-xs {
                padding: 4px 14px;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.15px;
                gap: 6px;

                svg {
                    min-width: 12px;
                    max-width: 12px;
                    min-height: 12px;
                    max-height: 12px;
                }
            }

            &.square-contained-xl {
                padding: 20px 35px;
                font-size: 20px;
                font-weight: 600;
                line-height: 23px;
                letter-spacing: 0.25px;
                gap: 12px;

                svg {
                    min-width: 22px;
                    max-width: 22px;
                    min-height: 22px;
                    max-height: 22px;
                }
            }

            &.square-text-md {
                padding: 9px 21px;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.175px;
                gap: 8px;

                svg {
                    min-width: 14px;
                    max-width: 14px;
                    min-height: 14px;
                    max-height: 14px;
                }
            }

            &.square-text-xs {
                padding: 4px 14px;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.15px;
                gap: 6px;

                svg {
                    min-width: 12px;
                    max-width: 12px;
                    min-height: 12px;
                    max-height: 12px;
                }
            }

            &.square-text-xl {
                padding: 20px 35px;
                font-size: 20px;
                font-weight: 600;
                line-height: 23px;
                letter-spacing: 0.25px;
                gap: 12px;

                svg {
                    min-width: 22px;
                    max-width: 22px;
                    min-height: 22px;
                    max-height: 22px;
                }
            }
        }

        &.square-icon {
            &.square-outline-xs {
                padding: 7px;

                svg {
                    min-width: 12px;
                    max-width: 12px;
                    min-height: 12px;
                    max-height: 12px;
                }
            }

            &.square-outline-md {
                padding: 11px;

                svg {
                    min-width: 14px;
                    max-width: 14px;
                    min-height: 14px;
                    max-height: 14px;
                }
            }

            &.square-outline-lg {
                padding: 13px;
                svg {
                    min-width: 18px;
                    max-width: 18px;
                    min-height: 18px;
                    max-height: 18px;
                }
            }

            &.square-outline-xl {
                padding: 19.5px;

                svg {
                    min-width: 22px;
                    max-width: 22px;
                    min-height: 22px;
                    max-height: 22px;
                }
            }

            &.square-contained-md {
                padding: 12px;

                svg {
                    min-width: 14px;
                    max-width: 14px;
                    min-height: 14px;
                    max-height: 14px;
                }
            }

            &.square-contained-xs {
                padding: 8px;

                svg {
                    min-width: 12px;
                    max-width: 12px;
                    min-height: 12px;
                    max-height: 12px;
                }
            }

            &.square-contained-lg {
                padding: 14px;

                svg {
                    min-width: 18px;
                    max-width: 18px;
                    min-height: 18px;
                    max-height: 18px;
                }
            }

            &.square-contained-xl {
                padding: 20.5px;

                svg {
                    min-width: 22px;
                    max-width: 22px;
                    min-height: 22px;
                    max-height: 22px;
                }
            }

            &.square-text-md {
                padding: 12px;

                svg {
                    min-width: 14px;
                    max-width: 14px;
                    min-height: 14px;
                    max-height: 14px;
                }
            }

            &.square-text-xs {
                padding: 8px;

                svg {
                    min-width: 12px;
                    max-width: 12px;
                    min-height: 12px;
                    max-height: 12px;
                }
            }

            &.square-text-lg {
                padding: 14px;

                svg {
                    min-width: 18px;
                    max-width: 18px;
                    min-height: 18px;
                    max-height: 18px;
                }
            }

            &.square-text-xl {
                padding: 20.5px;

                svg {
                    min-width: 22px;
                    max-width: 22px;
                    min-height: 22px;
                    max-height: 22px;
                }
            }
        }
    }

    &.float {
        border-radius: 5px;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
            0px 3px 3px 0px rgba(0, 0, 0, 0.12),
            0px 1px 8px 0px rgba(0, 0, 0, 0.2);

        &.float-default {
            &.float-outline {
                background: #fff;
                border: 1px solid #974900;

                color: #974900;

                svg {
                    path {
                        fill: #974900 !important;
                    }
                }

                &:hover {
                    background: #ffede2;
                }

                &:active {
                    background: #ffdbc4;
                }
            }

            &.float-contained {
                border: none;
                background: #974900;
                color: #fff;

                svg {
                    path {
                        fill: #fff;
                    }
                }

                &:hover {
                    background: #a25d1c;
                }

                &:active {
                    background: #a45f1f;
                }
            }
        }

        &.float-outline-xl {
            padding: 19px 21px;
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.25px;
            gap: 8px;

            svg {
                width: 22px;
                height: 22px;
            }
        }

        &.float-contained-xl {
            padding: 20px 22px;
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.25px;
            gap: 8px;

            svg {
                width: 22px;
                height: 22px;
            }
        }
    }

    &:disabled {
        background: #ece0db !important;
        border-color: #998f8a !important;

        color: #7e7570 !important;

        svg {
            fill: #7e7570 !important;
        }

        &:hover {
            background: #ece0db !important;
        }

        &:active {
            background: #ece0db !important;
        }
    }
}
</style>
