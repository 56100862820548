<template>
    <button
        title=""
        :disabled="disabled"
        :class="[
            customClass,
            {
                'save-button white-button': buttonType === 'side',
                'save-button brown-button': buttonType === 'save',
            },
        ]"
        @click="$emit('click')"
    >
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        buttonType: {
            type: "quantity" | "side" | "save",
            default: "side",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0;
}

.white-button {
    background: #fff;
    border: 1px solid #974900;
    color: #974900;

    svg {
        fill: #974900;
    }

    &:disabled {
        svg {
            fill: #7e7570;
        }
    }

    &:hover {
        background: #ffede2;
    }

    &:active {
        background: #ffdbc4;
    }
}

.brown-button {
    background: #974900;
    border: none;
    color: #fff;

    &:hover {
        background: #9f5714 !important;
    }

    &:active {
        background: #a45f1f !important;
    }
}

.save-button {
    display: flex;
    gap: 12px;
    border-radius: 5px;
    padding: 20px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0125em;

    &:disabled {
        background: #ece0db !important;
        color: #7e7570 !important;
        border-color: transparent !important;
    }
}

.footer-button {
    padding: 9px 21px !important;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.175px;
}

.footer-select-button {
    padding: 13px 28px;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.213px;
}

.side-button {
    height: 46px;
    min-width: 46px;
}

@media (max-width: 768px) and (min-width: 593px) {
    .footer-button {
        padding: 21px 0 !important;
        font-size: 20px;
        letter-spacing: 0.25px;
    }

    .footer-select-button {
        padding: 20px 35px;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.25px;
    }
}

@media (max-width: 593px) {
    .save-button {
        padding: 8px 0 !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .side-button {
        height: 28px !important;
        min-width: 28px !important;
    }

    .footer-button {
        padding: 3px 0 !important;
        font-size: 12px !important;
        line-height: 20px !important;
        letter-spacing: 0.15px !important;
    }
    .footer-select-button {
        padding: 9px 21px !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 20px !important;
        letter-spacing: 0.175px !important;
    }
}
</style>
