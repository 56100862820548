<template>
    <button @click="$emit('handleClick')" :class="{}">
        <component :is="iconComponent" />
        <span>{{ title }}</span>
    </button>
</template>

<script>
import OrderDetailsIcon from "@/assets/images/icons/order-details-icon.svg";
import ToolsIcon from "@/assets/images/icons/tools-icon.svg";

const icons = {
    orderDetails: OrderDetailsIcon,
    tools: ToolsIcon,
};

export default {
    components: { OrderDetailsIcon, ToolsIcon },
    props: {
        styleButton: {
            type: String,
            default: "default",
        },
        title: {
            type: String,
        },
        icon: {
            type: String,
        },
    },
    computed: {
        iconComponent() {
            return icons[this.icon];
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13.83px;

    width: 100%;
    height: 63px;
    padding: 20px 35px;

    background: #974900;
    border-radius: 5px;

    outline: 0;
    border: none;

    svg,
    svg > path {
        width: 18.33px;
        height: 22px;
    }
    svg {
        margin-top: -3px;
    }
    span {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #fff;
        user-select: none;
    }

    &:hover {
        background: #9f5714;
    }
    &:active {
        background: #a45f1f;
    }
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
    button {
        height: 38px;
        span {
            font-size: 14px !important;
            line-height: 20px !important;
        }
    }
}
</style>
