var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"location-field"},[_c('ModalHeader',{attrs:{"title":_vm.modalName,"close":_vm.closeModal}}),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"dropdown-block",attrs:{"id":"dropdown-block"}},[_c('div',{staticClass:"input-block"},[_c('b-form-group',{staticClass:"group-input"},[_c('SearchIcon',{staticClass:"search-icon"}),_c('b-form-input',{staticClass:"input",attrs:{"id":"location-input","value":_vm.locationFilter,"placeholder":_vm.$t('Operator.PlaceholderLocation'),"type":"text"},on:{"focus":function($event){_vm.device !== 'desktop' && _vm.fullScreenDropdown()}},nativeOn:{"input":function($event){return (function (e) { return _vm.searchByAddress(e.target.value); }).apply(null, arguments)}}})],1),(_vm.device !== 'desktop')?_c('Button',{staticClass:"button-cancel",attrs:{"button-type":"side"},on:{"click":_vm.removeFullScreenDropdown}},[_vm._v(" "+_vm._s(_vm.$t("IntegrationPage.cancel"))+" ")]):_vm._e()],1),_c('div',{staticClass:"horizontal-line"}),_c('div',{staticClass:"group-locations"},_vm._l((_vm.suggestions),function(location){return _c('div',{key:location.place_id},[_c('div',{class:[
                            'location-block',
                            _vm.selectedLocation === location.place_id
                                ? 'selected'
                                : '' ],on:{"mouseenter":function($event){_vm.hoveredLocation = location.place_id},"mouseleave":function($event){_vm.hoveredLocation = null},"click":function($event){return _vm.selectAddress(location.place_id)}}},[_c('LocationIcon',{staticClass:"location-icon"}),_c('div',[_vm._v(_vm._s(location.name))])],1),_c('div',{class:[
                            'horizontal-line',
                            _vm.hoveredLocation === location.place_id
                                ? 'hovered-line'
                                : '' ]})])}),0),_c('div',{staticClass:"horizontal-line"}),_c('div',{staticClass:"footer-locations"},[_c('Button',{attrs:{"button-type":"side"},on:{"click":_vm.getCurrentLocation}},[_c('CurrentLocation'),_vm._v(" "+_vm._s(_vm.$t("Operator.UseCurrentLocation"))+" ")],1)],1)]),_c('Map',{staticClass:"map-block",attrs:{"id":"map-block","current-coordinates":_vm.currentCoordinates},on:{"updateLocation":_vm.updateLocation,"click":_vm.fullScreenMap}}),_c('div',{staticClass:"map-options",attrs:{"id":"map-options"}},[_c('div',{staticClass:"address"},[_c('LocationIcon',{staticClass:"location-icon"}),_vm._v(" "+_vm._s(_vm.suggestions.length ? _vm.suggestions[0].name : "")+" ")],1),_c('div',{staticClass:"horizontal-line"}),_c('div',{staticClass:"buttons"},[_c('Button',{attrs:{"button-type":"side"},on:{"click":_vm.removeFullScreenMap}},[_vm._v(" "+_vm._s(_vm.$t("IntegrationPage.cancel"))+" ")]),_c('Button',{attrs:{"button-type":"save"},on:{"click":_vm.confirmLocation}},[_vm._v(" "+_vm._s(_vm.$t("Operator.ConfirmLocation"))+" ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }