const exhibitionLocationsEnum = Object.freeze({
    Setup: 1,
    Start: 2,
    Partial: 3,
    Stop: 4,
    Continue: 5,
    Finish: 6
});

export default exhibitionLocationsEnum;
