<template>
    <div class="select-modal-container animation-open-modal">
        <div>
            <ModalHeader
                :translation-base="translationBase"
                :title="title"
                :is-custom-fields="isCustomFields"
                :close="closeModal"
            />

            <ul class="list-content">
                <ItemList
                    v-for="(item, index) in list"
                    :key="`item-${index}`"
                    :selected-id="multiple ? selectedIds : selectedId"
                    :item-id="item.id"
                    @select-item="onSelectItem(item)"
                >
                    {{ item.name }}
                </ItemList>
            </ul>
        </div>

        <div v-if="multiple" class="footer-modal-select">
            <Button
                button-type="save"
                custom-class="footer-select-button"
                @click="onSave"
            >
                {{ $t("IntegrationPage.Save") }}
            </Button>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import Button from "@/views/pages/operator/components/InputPhotoModal/components/Button/Button.vue";
import ModalHeader from "./components/ModalHeader/ModalHeader.vue";
import ItemList from "./components/ItemList/ItemList.vue";

export default {
    components: {
        ModalHeader,
        ItemList,
        Button,
    },
    props: {
        title: {
            type: String,
            default: "Reason",
            required: true,
        },
        isCustomFields: {
            type: Boolean,
            default: false,
        },
        selectedId: {
            type: String | [],
            default: null,
        },
        list: {
            type: Array,
            default: [],
            required: true,
        },
        translationBase: {
            type: String,
            default: "",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedIds:
                this.multiple && Array.isArray(this.selectedId)
                    ? [...this.selectedId]
                    : [],
        };
    },
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
        this.addOpenAnimation();
    },
    methods: {
        ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
        onSelectItem(item) {
            if (this.multiple) {
                const index = this.selectedIds.indexOf(item.id);
                if (index === -1) {
                    this.selectedIds.push(item.id);
                } else {
                    this.selectedIds.splice(index, 1);
                }
            } else {
                this.$emit("select-item", { title: this.title, item });
                this.closeModal();
            }
        },
        addOpenAnimation() {
            const modal = document.querySelector(".select-modal-container");
            modal.classList.add("animation-open-modal");

            setTimeout(() => {
                modal.classList.remove("animation-open-modal");
            }, 1000);
        },
        onSave() {
            this.$emit("select-item", {
                title: this.title,
                items: this.selectedIds,
            });
            this.closeModal();
        },
        closeModal() {
            const modal = document.querySelector(".select-modal-container");
            modal.classList.add("animation-close-modal");
            modal.classList.add("closed-modal");

            setTimeout(() => this.$emit("close", this.title), 1000);
        },
    },
};
</script>

<style>
body:has(.select-modal-container) {
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.select-modal-container {
    height: 100vh;
    width: 100vw;
    background: #fcfcfc;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    display: flex;
    flex-direction: column;

    &.closed-modal {
        top: 100vh;
    }

    &.animation-open-modal {
        animation: openModal 0.5s normal;
        @keyframes openModal {
            0% {
                top: 100vh;
            }
            100% {
                top: 0;
            }
        }
    }

    &.animation-close-modal {
        animation: closeModal 0.5s normal;
        @keyframes closeModal {
            0% {
                top: 0;
            }
            100% {
                top: 100vh;
            }
        }
    }

    .list-content {
        &::-webkit-scrollbar {
            width: 16px; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #cfc4be; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            border: 4px solid #fff;
            height: 70px;
        }
        height: calc(100vh - 143px);
        overflow: auto;
        padding: 0;
        margin: 0;

        @media (max-width: 768px) and (min-width: 593px) {
            height: calc(100vh - 180px);
        }
        @media (max-width: 593px) {
            height: calc(100vh - 155px);
        }
        @media (max-width: 425px) {
            height: calc(100vh - 131px);
        }
    }

    .footer-modal-select {
        display: flex;
        justify-content: flex-end;
        padding: 15.5px 29px;
        border-top: 1px solid #cfc4be;
        background: #fff;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
            0px 3px 3px 0px rgba(0, 0, 0, 0.12),
            0px 1px 8px 0px rgba(0, 0, 0, 0.2);
    }
}
</style>
