var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectModal.open)?_c('SelectModal',{attrs:{"translation-base":"ProductionProgress.SelectModal.","title":_vm.selectModal.title,"selected-id":_vm.selectModal.selectedId,"list":_vm.selectModal.data,"multiple":_vm.field.industrialStructParameterType === 'select_multiple',"is-custom-fields":true},on:{"select-item":function (value) { return _vm.handleSelectModalClick(value, _vm.field); },"close":function($event){return _vm.handleCloseFieldModal()}}}):_vm._e(),(_vm.inputModal)?_c('InputModal',{attrs:{"data":_vm.selectedFieldInputModal,"is-custom-fields":true},on:{"setQuantityValue":function (context) { return _vm.setValueInputModal(context.quantity, _vm.field); },"close":function($event){return _vm.handleCloseFieldModal()}}}):_vm._e(),(_vm.inputText)?_c('InputTextModal',{attrs:{"modal-name":_vm.field.label,"input-value":_vm.fieldValue,"is-text-area":_vm.field.industrialStructParameterType === 'input_text_area' ? true : false},on:{"setValue":function (value) { return _vm.setValueInputModal(value, _vm.field); },"close":function($event){return _vm.handleCloseFieldModal()}}}):_vm._e(),(_vm.photoModal)?_c('InputPhotoModal',{attrs:{"modal-name":_vm.field.label,"input-value":_vm.fieldValue,"file-name":_vm.field.name},on:{"removePhoto":function($event){_vm.field.value = null;
            _vm.photoModal = false;},"handleFileUpload":function (event) { return _vm.handleFileUpload(event, _vm.field); },"close":function($event){return _vm.handleCloseFieldModal()}}}):_vm._e(),(_vm.locationModal)?_c('LocationModal',{attrs:{"value":_vm.fieldValue,"modal-name":_vm.field.label},on:{"close":function($event){return _vm.handleCloseFieldModal()},"setValue":function (value) { return _vm.setValueInputModal(value, _vm.field); }}}):_vm._e(),(
            _vm.field.industrialStructParameterType === 'datepicker' ||
            _vm.field.industrialStructParameterType === 'datetime' ||
            _vm.field.industrialStructParameterType === 'range_dates' ||
            _vm.field.industrialStructParameterType === 'input_hour'
        )?_c('DateTimePicker',{ref:"dateTimePickerRef",staticClass:"h-100",attrs:{"datepicker-id":("" + (_vm.field.id)),"position":"bottom","range":_vm.field.industrialStructParameterType === 'range_dates',"type":_vm.field.industrialStructParameterType === 'input_hour' ? 'time' : 'date',"week-days":_vm.setWeekDays(),"month-names":_vm.setMonths(),"block-dates":_vm.blockDates,"txt-cancel-button":_vm.$t('Datepicker.Cancel'),"txt-save-button":_vm.$t('Datepicker.Save'),"txt-time":_vm.$t('Datepicker.Time'),"txt-date":_vm.$t('Datepicker.Date'),"max-range-dates":_vm.maxRangeDates},on:{"input":function (value) { return _vm.handleDatepickerChange(value, _vm.field); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{class:[
                    'field-card-container',
                    { 'disabled-card': _vm.field.disabled },
                    { 'card-important': _vm.field.important }
                ],attrs:{"id":("field-" + (_vm.field.id))}},[_c('div',{staticClass:"container-icon"},[(_vm.isIconString(_vm.field.icon))?_c('div',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.field.icon)}}):_vm._e()]),_c('div',{class:['container-content', _vm.field.important ? 'pr-0' : '']},[_c('span',{staticClass:"container-title"},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]),_c('div',{staticClass:"container-info"},[_c('div',{staticClass:"container-value"},[_c('span',[_vm._v(_vm._s(_vm.getPlaceholder(_vm.field)))])])])]),(_vm.field.important)?_c('div',{staticClass:"important-container"},[_c('ImportantIcon')],1):_vm._e(),_c('Tooltip',{attrs:{"target":("field-" + (_vm.field.id)),"placement":"top"}},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("ProductionProgress.ImportantField"))+" ")])])])],1)]},proxy:true}],null,false,1971198188),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}}):_c('div',{class:[
            'field-card-container',
            { 'disabled-card': _vm.field.disabled },
            { 'card-important': _vm.field.important }
        ],attrs:{"id":("field-" + (_vm.field.id))},on:{"click":function($event){return _vm.openModalField(_vm.field)}}},[(_vm.loadingPhoto)?_c('div',{staticClass:"container-icon"},[_c('LoadSpinner',{staticClass:"loading"})],1):_c('div',{staticClass:"container-icon"},[(_vm.field.industrialStructParameterType === 'checkbox')?[_c('b-form-checkbox',{staticClass:"check-button p-0",attrs:{"id":_vm.field.name,"name":"resource-check-button","switch":false},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]:[(_vm.isIconString(_vm.field.icon))?_c('div',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.field.icon)}}):_vm._e()]],2),_c('div',{class:['container-content', _vm.field.important ? 'pr-0' : '']},[_c('span',{class:[
                    'container-title',
                    _vm.field.industrialStructParameterType === 'checkbox' ? 'p-0' : ''
                ]},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]),_c('div',{staticClass:"container-info"},[_c('div',{staticClass:"container-value"},[_c('span',[_vm._v(_vm._s(_vm.getPlaceholder(_vm.field)))])])])]),(_vm.field.important)?_c('div',{staticClass:"important-container"},[_c('ImportantIcon')],1):_vm._e(),_c('Tooltip',{attrs:{"target":("field-" + (_vm.field.id)),"placement":"top"}},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.$t("ProductionProgress.ImportantField")))])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }