<template>
    <div>
        <div
            :class="[
                {
                    'hide-scroll': !showOverflowProductionProgress || modal.opened
                }
            ]"
            id="production-progress"
        >
            <AppointmentHeader
                :component-id="'appointment-screen'"
                :back-label="headerBackLabel"
                :back-event="headerBackEvent"
                :orders="headerOrders"
                :status="headerStatus"
                :color="headerColor"
                @removeAppointment="removeAppointmentFromMultiple"
            />

            <main class="content-container">
                <div
                    :class="['activity-cards', moreThanOneCardShowing ? 'two-rows' : 'single-row']"
                >
                    <ActivityCard
                        v-if="cards.setup.show"
                        :title="$t('ProductionProgress.Setup')"
                        type="setup"
                        :show-add-button="false"
                        :active="cards.setup.active"
                        :datetime="cards.setup.datetime"
                        :timer="timer.card === 'setup' ? timer.data : null"
                        @handleClick="openAppointmentModal('Setup')"
                    />
                    <ActivityCard
                        v-if="cards.startPartial.show"
                        :title="
                            timer.card === 'stop'
                                ? $t('ProductionProgress.Continue')
                                : $t('ProductionProgress.Start')
                        "
                        :custom-active-label="$t('ProductionProgress.Partial')"
                        type="start"
                        show-add-button
                        :active="cards.startPartial.active"
                        :datetime="cards.startPartial.datetime"
                        :timer="timer.card === 'start' ? timer.data : null"
                        @handleClick="
                            openAppointmentModal(
                                `${cards.startPartial.active ? 'Partial' : 'Start'}`
                            )
                        "
                    />
                    <ActivityCard
                        v-if="cards.stop.show"
                        :title="$t('ProductionProgress.Stop')"
                        type="stop"
                        show-add-button
                        :active="cards.stop.active"
                        :datetime="cards.stop.datetime"
                        :timer="timer.card === 'stop' ? timer.data : null"
                        @handleClick="openAppointmentModal('Stop')"
                    />
                    <ActivityCard
                        v-if="cards.finish.show"
                        :title="
                            cards.finish.active
                                ? $t('ProductionProgress.Finished')
                                : $t('ProductionProgress.Finish')
                        "
                        type="finish"
                        :show-add-button="false"
                        :active="cards.finish.active"
                        :datetime="cards.finish.datetime"
                        @handleClick="cards.finish.active ? '' : openAppointmentModal('Finish')"
                    />
                </div>

                <div id="pdp__button-c">
                    <sys-button
                        class="tools-button-desktop"
                        scheme="float"
                        variant="outline"
                        size="xl"
                        :full="true"
                        @click="handleOpenRecordMaterialsModal"
                        v-if="defineStatus() !== 'finished'"
                    >
                        <ToolsIcon />
                        {{ $t("ProductionProgress.RecordMaterials") }}
                    </sys-button>

                    <sys-button
                        class="tools-button-desktop"
                        scheme="float"
                        variant="outline"
                        size="xl"
                        :full="true"
                        @click="handleOpenMaterialHistoryModal"
                        v-if="defineStatus() === 'finished'"
                    >
                        <ToolsIcon />
                        {{ $t("ProductionProgress.HistoricalMaterials") }}
                    </sys-button>

                    <sys-dropdown
                        class="buttons-mobile"
                        scheme="slip"
                        variant="contained"
                        size="md"
                        @click="redirectToOrderDetails"
                        full
                    >
                        <ShowDetailsIcon />
                        {{ $t("ProductionProgress.OrderDetails") }}

                        <template #droplist>
                            <b-dropdown-item
                                @click="openRecordMaterialsModal"
                                v-if="defineStatus() !== 'finished'"
                            >
                                <ToolsIcon />
                                {{ $t("ProductionProgress.RecordMaterials") }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="openMaterialHistoryModal"
                                v-if="defineStatus() === 'finished'"
                            >
                                <ToolsIcon />
                                {{ $t("ProductionProgress.HistoricalMaterials") }}
                            </b-dropdown-item>
                        </template>
                    </sys-dropdown>

                    <sys-button
                        class="orders-details-button-desktop"
                        scheme="float"
                        variant="contained"
                        size="xl"
                        :full="true"
                        @click="redirectToOrderDetails"
                    >
                        <ShowDetailsIcon />
                        {{ $t("ProductionProgress.OrderDetails") }}
                    </sys-button>
                </div>
            </main>
        </div>

        <AppointmentModal
            @removeAppointment="removeAppointmentFromMultiple"
            ref="appointmentModal"
            :allow-change-date="allowChangeDate"
            :is-control-by-hour="isControlByHour"
            :order-object="isMultipleOrders ? multiple : order"
            :format-datetime="formatDatetime"
            :default-produced-quantity="remainingQuantity"
            :default-date="defaultDate"
            :default-resource="resource"
            :allow-change-resource="allowChangeResource"
            :resources-list="resourcesList"
            :status-list="statusList"
            :rejection-list="refuseStatus"
            :open.sync="modal.opened"
            :title="returnAppointmentModalTitle()"
            :show-toast="showToast"
            :isMultipleOrders="isMultipleOrders"
            :orderListDisplay="
                isMultipleOrders ? multipleOperationsList : listOfOrderNameAndOperation
            "
            :customFields="customFields"
            :exhibitionLocationId="this.modal.typeId"
            @close="closeAppointmentModal"
            @confirmAppointment="confirmAppointment"
            :appointmentType="appointmentType"
        />

        <OrderDetailsModal
            v-if="showModalOrder"
            :open.sync="showModalOrder"
            :orderData="order"
            @close="onCloseDetailsModal"
            :isControlByHour="isControlByHour"
        />

        <OrderDetailsListModal
            v-if="showModalOrderDetailsList"
            :open.sync="showModalOrderDetailsList"
            @close="onCloseDetailsModal"
            :orderList="
                multiple.orders.map((order) => ({
                    preactorOrderId: order.preactor_order_id,
                    orderLabel: `${order.orderNumber}/${order.operationNumber}`
                }))
            "
        />

        <RecordMaterialsModal :open="flags.showRecordMaterialsModal" />

        <OrdersGroupMaterialModal :open="flags.showOrdersGroupMaterialModal" />

        <MaterialHistoryModal :open="flags.showMaterialHistoryModal" />

        <AddMaterialsModal :open="flags.showAddMaterialsModal" />

        <OperationsModal :open="flags.showOperationsModal" />
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";

    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import AppointmentModal from "@/views/pages/operator/components/AppointmentModal/AppointmentModal.vue";
    import moment from "moment";
    import AppointmentHeader from "@/views/pages/operator/components/AppointmentHeader";
    import ActivityCard from "./ActivityCard/index.vue";
    import Button from "./Button.vue";
    import { BDropdown, BDropdownItem } from "bootstrap-vue";
    import ToolsIcon from "@core/assets/formatted-icons/tools-icon.svg";
    import SysButton from "@/@core/components/sys-button/index.vue";
    import SysDropdown from "@/@core/components/sys-dropdown/index.vue";
    import OrderDetailsIcon from "@/assets/images/icons/order-details-icon.svg";
    import ShowDetailsIcon from "@core/assets/formatted-icons/show-details-icon.svg";
    import exhibitionLocationsEnum from "../enums/exhibitionLocationsIds.js";
    import { formatCurrencyWithouSymbol } from "@/views/pages/operator/utils";

    export default {
        components: {
            ActivityCard,
            OrderDetailsIcon,
            ShowDetailsIcon,
            SysButton,
            SysDropdown,
            BDropdown,
            BDropdownItem,
            AppointmentHeader,
            Button,
            AppointmentModal,
            ToolsIcon,
            ToastificationContent,
            OperationsModal: () =>
                import("@/views/pages/operator/components/OperationsModal/index.vue"),
            AddMaterialsModal: () =>
                import("@/views/pages/operator/components/AddMaterialsModal/index.vue"),
            MaterialHistoryModal: () =>
                import("@/views/pages/operator/components/MaterialHistoryModal/index.vue"),
            OrdersGroupMaterialModal: () =>
                import("@/views/pages/operator/components/OrdersGroupMaterialModal/index.vue"),
            RecordMaterialsModal: () =>
                import("@/views/pages/operator/components/RecordMaterialsModal/index.vue"),
            OrderDetailsModal: () =>
                import("@/views/pages/operator/components/OrderModal/OrderModal.vue"),
            OrderDetailsListModal: () =>
                import("@/views/pages/operator/components/OrderListModal/index.vue")
        },
        computed: {
            ...mapGetters("production_progress", ["flags"]),
            headerBackLabel() {
                const { appointment_type } = this.$router.currentRoute.params;
                return appointment_type == false
                    ? this.$t("ProductionProgress.ProductiveNote")
                    : this.$t("ProductionProgress.ReworkNote");
            },
            headerOrders() {
                return this.isMultipleOrders
                    ? this.multipleOperationsList
                    : [
                          {
                              id: 0,
                              name: `${this.order.orderNumber}/${this.order.operationNumber}`
                          }
                      ];
            },
            headerStatus() {
                return this.isMultipleOrders
                    ? this.multiple?.lastInfo?.lastStatus
                    : this.order?.lastByUser?.lastStatusByUser;
            },
            headerColor() {
                if (this.isMultipleOrders) return this.multiple?.lastInfo?.lastColorStatus;
                return this.isControlByHour
                    ? this.order?.lastByUser?.lastColorStatus
                    : this.order?.lastInfo?.lastColorStatus;
            },
            appointmentType() {
                return this.$router.currentRoute.params.appointment_type;
            },
            moreThanOneCardShowing() {
                return (
                    Object.keys(this?.cards).filter((card) => this?.cards?.[card]?.show)?.length > 1
                );
            }
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("production_progress", [
                "UPDATE_FLAGS_IS_MULTIPLE_ORDERS",
                "UPDATE_RECORD_MATERIALS_MODAL_MATERIALS",
                "UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST",
                "UPDATE_RECORD_MATERIALS_MODAL_HISTORY",
                "UPDATE_MATERIAL_HISTORY_MODAL_HISTORY",
                "UPDATE_RECORD_MATERIALS_MODAL_ORDER_GROUP_MATERIALS",
                "UPDATE_OPERATIONS_MODAL_OPERATIONS",
                "UPDATE_OPERATIONS_MODAL_FILTERED_OPERATIONS_LIST"
            ]),
            ...mapActions("production_progress", [
                "openRecordMaterialsModal",
                "openMaterialHistoryModal"
            ]),
            ...mapActions("app", ["removeRequestLoading"]),
            async handleOpenMaterialHistoryModal() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.getMaterialsHistory();

                    this.UPDATE_MATERIAL_HISTORY_MODAL_HISTORY([...response]);
                    this.openMaterialHistoryModal();
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async getMaterialsMultiple() {
                const { preactor_orders } = this.$router.currentRoute.params;
                const GET_MATERIALS_URL = `/api/mysfc/operation/multiple/materials?operationIds=${preactor_orders}`;
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_MATERIALS_URL);

                    const formattedMaterials =
                        response?.data?.data?.map((material) => ({
                            ...material,
                            totalConsumed: formatCurrencyWithouSymbol(material.totalConsumed),
                            operations: material.operations.map((e) => ({
                                ...e,
                                quantityConsumed: String(e.quantityConsumed),
                                valid: null
                            }))
                        })) || [];

                    return Promise.resolve(formattedMaterials);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            async getMaterials() {
                const { order_id } = this.$router.currentRoute.params;
                const GET_MATERIALS_URL = `/api/mysfc/operation/${order_id}/materials`;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_MATERIALS_URL);

                    const formattedMaterials =
                        response?.data?.data?.map((material) => ({
                            ...material,
                            valid: null,
                            quantityConsumed: formatCurrencyWithouSymbol(material.quantityConsumed)
                        })) || [];

                    return Promise.resolve(formattedMaterials);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            async getMaterialsHistory() {
                const { order_id } = this.$router.currentRoute.params;
                const GET_MATERIALS_HISTORY_URL = `/api/mysfc/operation/${order_id}/materials-history`;

                try {
                    const response = await this.$http2.get(GET_MATERIALS_HISTORY_URL);

                    const formattedMaterialsHistory = [];

                    response?.data?.data?.forEach((material) => {
                        material?.history?.forEach((history) => {
                            formattedMaterialsHistory.push({
                                ...material,
                                ...history
                            });
                        });
                    });

                    return Promise.resolve(formattedMaterialsHistory);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            async handleOpenRecordMaterialsModal() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const [materialsHistoryResponse, materialsResponse] = await Promise.all([
                        this.getMaterialsHistory(),
                        this.isMultipleOrders ? this.getMaterialsMultiple() : this.getMaterials()
                    ]);

                    this.UPDATE_RECORD_MATERIALS_MODAL_ORDER_GROUP_MATERIALS([
                        ...materialsResponse
                    ]);
                    this.UPDATE_RECORD_MATERIALS_MODAL_MATERIALS([...materialsResponse]);
                    this.UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST([
                        ...materialsResponse
                    ]);
                    this.UPDATE_RECORD_MATERIALS_MODAL_HISTORY([...materialsHistoryResponse]);

                    this.openRecordMaterialsModal();
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            headerBackEvent() {
                this.$router.push({
                    name: "operator-orders-all",
                    params: { prefix: localStorage.getItem("prefix") }
                });
            },
            async onCloseDetailsModal(value) {
                this.showModalOrderDetailsList = false;
                this.showModalOrder = false;
                this.showOverflowProductionProgress = true;

                if (!this.isMultipleOrders) {
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    await this.getOrder(true, this.appointmentType);
                    this.setCardsData();
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async removeAppointmentFromMultiple(appointmentId) {
                const { preactorsOrdersId } = this;
                const orders = preactorsOrdersId.split(",");
                const params = this.$route.params;
                const updatedOrders = orders.filter(
                    (order) => Number(order) !== Number(appointmentId)
                );
                const updatedParams = {
                    ...params,
                    preactor_orders: updatedOrders.join(",")
                };

                if (updatedOrders.length === 1) {
                    // Se tiver somente 1 ordem, redirecionar para apontamento individual
                    const resourceId = this.multiple?.resource[0].id;
                    const is_rework = this.appointmentType;
                    const orderParams = {
                        prefix: params.prefix,
                        return_orders: "true",
                        multiple: "false",
                        order_id: String(updatedOrders[0]),
                        resource_id: resourceId,
                        orders: [],
                        appointment_type: is_rework
                    };

                    this.$router
                        .push({
                            name: "operator-production-progress",
                            params: orderParams
                        })
                        .then(() => {
                            this.$router.go();
                        });

                    return;
                }

                this.$router.replace({
                    params: updatedParams
                });

                await this.loadPage(this.appointmentType);
            },
            returnAppointmentModalTitle() {
                const { modal, cards } = this;

                if ((modal.type === "Partial" || modal.type === "Start") && cards.stop.active) {
                    this.modal.typeId = exhibitionLocationsEnum.Continue;
                    return "Continue";
                }

                return modal.type;
            },
            openAppointmentModal(type) {
                this.createListOfOrderNameAndOperation();
                switch (type) {
                    case "Setup":
                        this.modal.typeId = exhibitionLocationsEnum.Setup;

                        break;
                    case "Start":
                        this.modal.typeId = exhibitionLocationsEnum.Start;

                        break;
                    case "Partial":
                        this.modal.typeId = exhibitionLocationsEnum.Partial;

                        break;

                    case "Stop":
                        this.modal.typeId = exhibitionLocationsEnum.Stop;

                        break;
                    case "Continue":
                        this.modal.typeId = exhibitionLocationsEnum.Continue;

                        break;
                    case "Finish":
                        this.modal.typeId = exhibitionLocationsEnum.Finish;

                        break;

                    default:
                        this.modal.typeId = null;
                        break;
                }
                this.modal.type = type;
                this.modal.opened = true;
            },
            closeAppointmentModal() {
                this.modal.opened = false;

                this.$nextTick(() => {
                    this.modal.type = "";
                    this.modal.typeId = null;
                });
            },
            getSelectedStatusId(data) {
                const findStatusId = (term) =>
                    this.statusList.filter((item) => item.text === term)[0].value;
                const modal = this.modal.type.toLowerCase();

                const currentStatus = (() => {
                    if (modal === "stop") return data.status.id;

                    if (modal === "start" || modal === "partial" || modal === "continue") {
                        const noActiveCard = !Object.keys(this.cards).some(
                            (item) => this.cards[item].active
                        );

                        if (this.cards.setup.active || noActiveCard)
                            return findStatusId("Iniciado");

                        return findStatusId("Executando");
                    }

                    if (modal === "setup") return findStatusId("Setup");
                    if (modal === "finish") return findStatusId("Operação Finalizada");
                })();

                return currentStatus;
            },
            async confirmAppointment(data) {
                const currentDateFormat =
                    this.$cookies.get("userInfo").language === "pt-BR"
                        ? "DD-MM-YYYY HH:mm"
                        : "MM-DD-YYYY HH:mm";

                const event_time = moment(data.date.value, currentDateFormat).format(
                    "YYYY-MM-DD HH:mm"
                );

                const statusId = this.getSelectedStatusId(data);
                const is_rework = this.appointmentType;
                const modal = this.modal.type.toLowerCase();

                await this.createNewAppointment(
                    statusId,
                    event_time,
                    is_rework == true || is_rework == "1"
                        ? true
                        : data.produced.value
                        ? data.produced.value
                        : false,
                    data.rejection.value ? data.rejection.value : 0,
                    data.rework.value ? data.rework.value : 0,
                    data.reason.id ? data.reason.id : 0,
                    data.resource.id,
                    is_rework
                );
                await this.loadPage(modal == "finish" ? 0 : is_rework);
                this.closeAppointmentModal();
            },
            formatToISOString(dateString) {
                if (!dateString) return;

                if (dateString.includes("T")) {
                    return dateString;
                }

                const [date, time] = dateString.split(" ");
                const [year, month, day] = date.split("-");
                const timeWithSeconds = time.includes(":") ? `${time}:00` : time;

                return new Date(`${year}-${month}-${day}T${timeWithSeconds}`).toISOString();
            },
            redirectToOrderDetails() {
                // Redirect to order details modal
                if (this.isMultipleOrders) {
                    this.showModalOrderDetailsList = true;
                } else {
                    this.showModalOrder = true;
                }
                this.showOverflowProductionProgress = false;
            },
            formatDatetime(date) {
                if (!date) return null;

                const language = String(this.$i18n.locale);
                const PT_FORMAT = "DD-MM-YYYY HH:mm";
                const EN_FORMAT = "MM-DD-YYYY HH:mm";

                if (date instanceof Date || moment.isMoment(date) || moment(date).isValid()) {
                    // Se for objeto Date do JS ou for objeto Moment
                    if (language == "pt-BR") {
                        return moment(date).format(PT_FORMAT);
                    }

                    return moment(date).format(EN_FORMAT);
                }
                // Se for formatado da própria aplicação (mudança de idioma)
                if (language === "pt-BR") {
                    return moment(date, EN_FORMAT).format(PT_FORMAT);
                }
                return moment(date, PT_FORMAT).format(EN_FORMAT);
            },
            resetTimer() {
                clearInterval(this.timer.interval);
                this.timer.startingTime = null;
                this.timer.elapsedTime = null;
            },
            formatTimer(str) {
                return str.toString().padStart(2, "0");
            },
            createTimer(startingTime = 0) {
                const alreadyHasTimerRunning = !!this?.timer?.interval;

                if (alreadyHasTimerRunning) this.resetTimer();

                this.timer.startingTime = moment(startingTime);
                this.timer.elapsedTime = moment().valueOf() - this.timer.startingTime.valueOf();

                this.timer.interval = setInterval(() => {
                    this.timer.elapsedTime += 1000;

                    const { elapsedTime } = this.timer;
                    const second = this.formatTimer(Math.floor(elapsedTime / 1000) % 60);
                    const minute = this.formatTimer(Math.floor(elapsedTime / 1000 / 60) % 60);
                    const hour = this.formatTimer(Math.floor(elapsedTime / 1000 / 60 / 60));

                    this.timer.data = {
                        hour,
                        minute,
                        second
                    };
                }, 1000);
            },
            defineStatus() {
                const orderInfo = this.isMultipleOrders ? this.multiple : this.order;

                // Definindo os dados dos cards
                const status = this.isControlByHour
                    ? orderInfo?.lastByUser?.lastStatusByUser
                    : orderInfo?.lastInfo?.lastSetup;

                switch (status) {
                    case "Iniciado":
                    case "Started":
                        return "started";
                    case "Não Iniciado":
                        return "not-started";
                    case "Setup":
                        return "setup";
                    case "Executando":
                    case "Running":
                        return "running";
                    case "Completed":
                    case "Operação Finalizada":
                        return "finished";
                    default:
                        return "stopped";
                }
            },
            setCardsTimestamps(lastActivities) {
                this.cards.setup.datetime = this.formatDatetime(lastActivities.setup);
                this.cards.startPartial.datetime = lastActivities.running
                    ? this.formatDatetime(lastActivities.running)
                    : this.formatDatetime(lastActivities.start);
                this.cards.stop.datetime = this.formatDatetime(lastActivities.stop);
                this.cards.finish.datetime = this.formatDatetime(lastActivities.finished);
            },
            setFormDefaultValues() {
                const orderInfo = this.isMultipleOrders ? this.multiple : this.order;

                // Definindo valores padrão para datepicker da modal (Setup e Start)
                this.defaultDate.setup =
                    orderInfo.setup_start && moment(orderInfo.setup_start).isBefore(new Date())
                        ? moment(orderInfo.setup_start).toDate()
                        : new Date();

                this.defaultDate.start =
                    orderInfo.start_time && moment(orderInfo.start_time).isBefore(new Date())
                        ? moment(orderInfo.start_time).toDate()
                        : new Date();
            },

            setCardsData() {
                // Definindo apontamento individual / em massa
                const orderInfo = this.isMultipleOrders ? this.multiple : this.order;

                // Definindo os dados dos cards
                const status = this.defineStatus();

                let startingTime = null;
                let cardsToShow = ["setup", "start", "stop"];

                const lastActivities = {
                    start: this.isControlByHour
                        ? orderInfo.lastByUser.lastStartedByUser
                        : orderInfo.lastInfo.lastStarted,
                    running: this.isControlByHour
                        ? orderInfo.lastByUser.lastRunningByUser
                        : orderInfo.lastInfo.lastRunning,
                    finished: this.isControlByHour
                        ? orderInfo.lastByUser.lastCompletedByUser
                        : orderInfo.lastInfo.lastCompleted,
                    setup: this.isControlByHour
                        ? orderInfo.lastByUser.lastSetupByUser
                        : orderInfo.lastInfo.lastSetup,
                    stop: this.isControlByHour
                        ? orderInfo.lastByUser.lastStoppedByUser
                        : orderInfo.lastInfo.lastStopped
                };

                this.lastActivities = lastActivities;

                const active = (() => {
                    if (status === "not-started") return false;

                    if (status === "started") {
                        startingTime = lastActivities.start;
                        return "start";
                    }

                    if (status === "running") {
                        startingTime = lastActivities.running;
                        return "start";
                    }

                    if (status === "finished") {
                        startingTime = lastActivities.finished;
                        cardsToShow = ["finish"];
                        return "finish";
                    }

                    if (status === "setup") {
                        startingTime = lastActivities.setup;
                        return "setup";
                    }

                    startingTime = lastActivities.stop;
                    return "stop";
                })();

                startingTime && this.createTimer(startingTime);
                this.timer.card = active;

                // Card Finalizar só aparece caso esteja executando ou finalizado
                const showCard = (card) =>
                    card === "finish"
                        ? ["finish", "start"].includes(active)
                        : cardsToShow.includes(card);

                // Definindo regras e valores dos cards
                this.cards = {
                    setup: {
                        show: showCard("setup"),
                        active: active === "setup"
                    },
                    startPartial: {
                        show: showCard("start"),
                        active: active === "start"
                    },
                    stop: {
                        show: showCard("stop"),
                        active: active === "stop"
                    },
                    finish: {
                        show: showCard("finish"),
                        active: active === "finish"
                    }
                };

                // Definindo horários de último apontamento e valores padrão de formulários
                this.setCardsTimestamps(lastActivities);
                this.setFormDefaultValues();
            },
            async createNewAppointment(
                status,
                event_time,
                produced_quantity,
                refuse,
                rework,
                rejection_reason_id,
                resource_id,
                is_rework
            ) {
                const customFields = this.customFields.filter((e) =>
                    e.exhibitionLocationIds.includes(this.modal.typeId)
                );
                const parameters = customFields.flatMap((e) => {
                    if (
                        e.industrialStructParameterType === "select_multiple" &&
                        e.value?.length > 0
                    ) {
                        return e.value.map((f) => {
                            return {
                                id: e.id,
                                value: f ? String(f) : null
                            };
                        });
                    }
                    return {
                        id: e.id,
                        value: e.value ? e.value : null
                    };
                });
                if (this.idsNotFound) {
                    for (const value of this.idsNotFound) {
                        let ids = this.preactorsOrdersId.split(",");
                        let index = ids.indexOf(value);
                        while (index !== -1) {
                            ids.splice(index, 1);
                            index = ids.indexOf(value, index);
                        }
                        this.preactorsOrdersId = ids.join(",");
                    }
                }

                const operationsIds = this.multiple?.operations?.map((e) => e.operationId) || [];

                const body = {
                    operationId: this.isMultipleOrders ? operationsIds : this.order.id,
                    userGuid: this.$cookies.get("userInfo").userData.guid,
                    statusId: status,
                    producedQuantity: Number(produced_quantity),
                    refuse,
                    rework,
                    resourceId: resource_id,
                    eventTime: this.formatToISOString(event_time),
                    refuseReasonId: rejection_reason_id !== 0 ? rejection_reason_id : null,
                    isRework: is_rework || is_rework === "true" ? true : false,
                    industrialParameter: parameters
                };

                await (this.isMultipleOrders
                    ? this.createMultipleAppointment(body)
                    : this.createSingleAppointment(body));
            },
            async createSingleAppointment(body) {
                const POST_CREATE_SINGLE_APPOINTMENT_URL = `/api/mysfc/operator/operation-progress?siteId=${this.currentSite}`;

                try {
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    await this.$http2.post(POST_CREATE_SINGLE_APPOINTMENT_URL, body);

                    if (this.isMultipleOrders) {
                        this.showToast(
                            this.$t("ProductionProgress.Success"),
                            "FrownIcon",
                            this.$t("ProductionProgress.OperationProgressRegistred"),
                            "success"
                        );
                        return;
                    }

                    this.showToast(
                        this.$t("ProductionProgress.Toasts.Success.Title"),
                        "FrownIcon",
                        this.$t("ProductionProgress.Toasts.Success.Content"),
                        "success"
                    );
                } catch (error) {
                    this.showToast(
                        this.$t("Error"),
                        "FrownIcon",
                        this.$t(error.response.data.message),
                        "danger"
                    );
                } finally {
                    this.removeRequestLoading();
                }
            },
            async createMultipleAppointment(body) {
                const POST_CREATE_MULTIPLE_APPOINTMENT_URL = `/api/mysfc/operator/multiple/operation-progress`;

                try {
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    await this.$http2.post(POST_CREATE_MULTIPLE_APPOINTMENT_URL, body);

                    if (this.isMultipleOrders) {
                        this.showToast(
                            this.$t("ProductionProgress.Success"),
                            "FrownIcon",
                            this.$t("ProductionProgress.OperationProgressRegistred"),
                            "success"
                        );
                        return;
                    }

                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        `${this.$t("ProductionProgress.OperationProgressRegistred")}: ${this.$t(
                            "ProductionProgress.Order"
                        )} ${this.order.orderNumber}`,
                        "success"
                    );
                } catch (error) {
                    this.sendingForm = false;
                    this.idsNotFound = error.response.data.idsNotFound;
                    let toastText = "";
                    if (this.idsNotFound) {
                        const orderNotFound = this.idsNotFound.map((id) => {
                            return this.multiple.orders.find(
                                (order) => order.preactor_order_id === id
                            );
                        });
                        const getOrderNamesById = orderNotFound
                            .map((order) => `${order.orderNumber}/${order.operationNumber}`)
                            .join(", ");
                        if (orderNotFound.length > 1) {
                            toastText = `${this.$t(
                                "ProductionProgress.Toasts.OrderNotFoundPlural1"
                            )} ${getOrderNamesById}${this.$t(
                                "ProductionProgress.Toasts.OrderNotFoundPlural2"
                            )}`;
                        } else if (orderNotFound.length === 1) {
                            toastText = `${this.$t(
                                "ProductionProgress.Toasts.OrderNotFoundSingular1"
                            )} ${getOrderNamesById} ${this.$t(
                                "ProductionProgress.Toasts.OrderNotFoundSingular2"
                            )}`;
                        }
                    } else {
                        toastText = error.response.data.message;
                    }

                    this.showToast(this.$t("Error"), "FrownIcon", toastText, "danger");
                } finally {
                    this.removeRequestLoading();
                }
            },
            async getContext() {
                const GET_CONTEXT_URL = `/api/mysfc/operation-progress/context?siteId=${this.currentSite}`;

                try {
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    const response = await this.$http2.get(GET_CONTEXT_URL);
                    const data = response.data.data;
                    this.statusList = data.status.map((e) => ({
                        ...e,
                        text: e.name,
                        value: e.id
                    }));
                    this.refuseStatus = data.refuseReasons.map((e) => ({
                        ...e,
                        text: e.name,
                        value: e.id
                    }));
                    this.customFields = data.industrialParameters
                        .map((e) => {
                            if (e.industrialStructParameterType === "checkbox") {
                                return e.industrialStructParameterOption.map((option) => ({
                                    ...e,
                                    id: option.id,
                                    name: option.name,
                                    label: option.name
                                }));
                            } else {
                                return e;
                            }
                        })
                        .flat();
                } catch (error) {
                    console.error(error);
                } finally {
                    this.removeRequestLoading();
                }
            },
            async getResources(useLoading = true) {
                this.resourcesList = [];
                const GET_RESOURCES_URL = `/api/mysfc/resource/workcenters?siteId=${this.currentSite}`;

                try {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    const response = await this.$http2.get(GET_RESOURCES_URL);

                    const data = response.data;

                    const resources = data
                        .filter((resource) =>
                            this.isMultipleOrders ? !resource?.control_by_hour : true
                        )
                        ?.map((resource) => ({
                            text: resource.name,
                            value: resource.id,
                            allow_change_resource: resource.allowResourceChange,
                            allow_change_date: resource.allowChangeDate
                        }));

                    this.resourcesList = resources;

                    this.isControlByHour = data.find(
                        (element) => element.id == this.$router.currentRoute.params.resource_id
                    )?.controlByHour;

                    this.allowChangeResource = data.find(
                        (element) => element.id == this.$router.currentRoute.params.resource_id
                    )?.allowResourceChange;

                    this.allowChangeDate = data.find(
                        (element) => element.id == this.$router.currentRoute.params.resource_id
                    )?.allowChangeDate;
                } catch (error) {
                    console.error(error);
                } finally {
                    useLoading && this.removeRequestLoading();
                }
            },
            async getOrders(useLoading = true, is_rework = 0) {
                let ids = [];

                if (this.$router.currentRoute.params.orders) {
                    ids = this.$router.currentRoute.params.orders
                        .map((order) => order.operation?.id)
                        .filter((id) => id !== null && id !== undefined);
                } else {
                    ids = this.$router.currentRoute.params.preactor_orders.split(",");
                }

                let GET_ORDERS_URL = `api/mysfc/operator/multiple/operations?Ids=${ids}&SiteId=${this.currentSite}&IsRework=${is_rework}`;

                try {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    const response = await this.$http2.get(GET_ORDERS_URL);
                    const data = response.data.data;

                    this.multiple = data;
                    this.resource = {
                        value: data.resource[0].id,
                        text: data.resource[0].name
                    };

                    this.multipleOperationsList = this.multiple.operations.map((order) => ({
                        name: `${order.orderNumber}/${order.operationNumber}`,
                        id: order.operationId
                    }));

                    const localOperations = data.operations.map((e) => ({
                        id: e.operationId,
                        name: e.operationName
                    }));

                    this.UPDATE_OPERATIONS_MODAL_OPERATIONS([...localOperations]);
                    this.UPDATE_OPERATIONS_MODAL_FILTERED_OPERATIONS_LIST([...localOperations]);
                } catch (error) {
                    console.error(error);
                } finally {
                    this.setCardsData();
                    useLoading && this.removeRequestLoading();
                }
            },
            async getOrder(useLoading = true, is_rework = 0) {
                const GET_ORDER_URL = `/api/mysfc/operator/operation/${this.$router.currentRoute.params.order_id}?siteId=${this.currentSite}`;
                try {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    const response = await this.$http2.get(GET_ORDER_URL);
                    const data = response.data.data;

                    this.order = data;
                    this.resource = {
                        value: data.resource[0].id,
                        text: data.resource[0].name
                    };

                    this.createListOfOrderNameAndOperation();

                    this.producedQuantity = data?.producedQuantity
                        ? Number(data.producedQuantity)
                        : 0;
                    this.plannedQuantity = data?.plannedQuantity ? Number(data.plannedQuantity) : 0;
                    this.remainingQuantity = Math.max(
                        this.plannedQuantity - this.producedQuantity,
                        0
                    );

                    const convertPercentage = (number) =>
                        `${(parseFloat(number) * 100).toFixed(0)}%`;

                    this.percentProductive = convertPercentage(this.order.completedProgress);
                    this.percentCompleted = convertPercentage(this.order.timeConsumed);
                    this.progressoPrevisto = convertPercentage(this.order.theoreticalProgress);
                    this.progressoReal = convertPercentage(this.order.realProgress);
                } catch (error) {
                    console.error(error);
                } finally {
                    useLoading && this.removeRequestLoading();
                }
            },
            createListOfOrderNameAndOperation() {
                const { order, isMultipleOrders, multiple } = this;
                if (isMultipleOrders) {
                    const list = multiple.operations.map(
                        ({ id, orderNumber, operationNumber }) => ({
                            preactorOrderId: id,
                            orderLabel: `${orderNumber}/${operationNumber}`
                        })
                    );

                    this.listOfOrderNameAndOperation = [...list];
                    return list;
                }

                const { id, orderNumber, operationNumber } = order;

                const list = [
                    {
                        id: id,
                        name: `${orderNumber}/${operationNumber}`
                    }
                ];

                this.listOfOrderNameAndOperation = [...list];
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        variant
                    }
                });
            },
            showSwal(title, icon, text) {
                return this.$swal({
                    title,
                    text,
                    icon,
                    showCancelButton: true,
                    confirmButtonText: "Yes!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                });
            },
            async loadPage(is_rework) {
                const isMultipleOrders =
                    this.$router.currentRoute.params.multiple?.toString() === "true";

                this.isMultipleOrders = isMultipleOrders;
                this.UPDATE_FLAGS_IS_MULTIPLE_ORDERS(isMultipleOrders);

                this.infoOperacao = this.$router.currentRoute.params.multiple != "true";

                this.selectedOrders = this.isMultipleOrders
                    ? this.$router.currentRoute.params.orders
                    : [];

                this.preactorsOrdersId = this.$router.currentRoute.params.preactor_orders;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                await (this.isMultipleOrders
                    ? this.getOrders(false, is_rework)
                    : this.getOrder(false, is_rework));
                await this.getResources(false);
                await this.getContext();
                this.setCardsData();
                this.removeRequestLoading();
            },
            handleLanguageChange() {
                this.setCardsTimestamps(this.lastActivities);
            }
        },
        data() {
            return {
                multipleOperationsList: [],
                multiple: {
                    orders: [],
                    status: {
                        name: "",
                        color: ""
                    }
                },
                idsNotFound: [],
                listOfOrderNameAndOperation: [],
                resource: {
                    value: null,
                    text: null
                },
                producedQuantity: 0,
                plannedQuantity: 0,
                remainingQuantity: 0,
                defaultDate: {
                    setup: "",
                    start: ""
                },
                statusObject: {},
                timer: {
                    card: "",
                    data: {
                        hour: "00",
                        minute: "00",
                        second: "00"
                    },
                    interval: null,
                    startingTime: 1686669022126
                },
                resourcesList: [],
                isMultipleOrders: false,
                resource: null,
                isControlByHour: false,
                allowChangeResource: false,
                allowChangeDate: false,

                percentProductive: null,
                percentCompleted: null,
                progressoPrevisto: null,
                progressoReal: null,

                infoOperacao: null,
                selectedOrders: null,
                preactorsOrdersId: null,

                attributesFields: null,

                refuseStatus: [],
                customFields: [],
                statusList: [],

                user_id: JSON.parse(localStorage.getItem("userData")).guid,

                currentSite: this.$cookies.get("userInfo").currentSite,
                prefix: localStorage.getItem("prefix") || this.$router.currentRoute.params.prefix,

                modal: {
                    opened: false,
                    typeId: null,
                    type: ""
                },
                order: {
                    last_status_by_user: "",
                    last_color_status_by_user: "",
                    order_no: "",
                    op_no: ""
                },
                cards: {
                    setup: {
                        show: true,
                        active: false
                    },
                    startPartial: {
                        show: true,
                        active: false
                    },
                    stop: {
                        show: true,
                        active: false
                    },
                    finish: {
                        show: true,
                        active: false
                    }
                },
                showModalOrder: false,
                showOverflowProductionProgress: true,
                showModalOrderDetailsList: false
            };
        },
        async created() {
            await this.loadPage(this.$router.currentRoute.params.appointment_type);
        },
        mounted() {
            this.$watch("$i18n.locale", function () {
                this.handleLanguageChange();
            });
        }
    };
</script>

<style lang="scss">
    body:has(.hide-scroll) {
        overflow: hidden !important;
    }

    .app-content-operator:has(#production-progress) {
        padding: 0 !important;
    }
</style>

<style lang="scss" scoped>
    #production-progress {
        // Adaptando ao container app-content
        width: 100%;
        min-height: 84vh;

        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .content-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 92vh;
            width: inherit;
            padding: 124px 24px 24px;

            .activity-cards {
                display: flex;
                flex: 1;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 30px;
                margin-bottom: 24px;
            }
        }
    }
    .two-rows {
        grid-template-columns: 1fr 1fr !important;
    }
    .single-row {
        grid-template-columns: 1fr !important;
    }
    #pdp__button-c {
        margin-top: auto;
        display: flex;
        gap: 30px;

        .tools-button-desktop {
            display: flex;
        }

        .buttons-mobile {
            display: none;
        }

        .orders-details-button-desktop {
            display: flex;
        }
    }
    @media (max-width: 1100px) {
        .activity-cards {
            display: grid !important;
        }
    }

    @media (max-width: 768px) and (min-width: 714px) {
        #production-progress {
            .content-container {
                height: auto;
                padding-top: 222px;
            }
        }
    }

    @media (max-width: 714px) and (min-width: 480px) {
        #production-progress {
            .content-container {
                height: auto;
                padding-top: 270px;
            }
        }
    }

    @media (max-width: 550px) {
        .activity-cards {
            grid-template-columns: 1fr !important;
        }
    }

    @media (max-width: 480px) {
        .content-container {
            padding-top: 250px !important;
            padding-bottom: 11px !important;
            height: auto !important;

            #pdp__button-c {
                .tools-button-desktop {
                    display: none;
                }

                .buttons-mobile {
                    display: flex;
                }

                .orders-details-button-desktop {
                    display: none;
                }

                width: 100%;
                padding: 16px;
                position: fixed;
                left: 0;
                bottom: 0;
                z-index: 99;
            }
        }
    }
</style>
